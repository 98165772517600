import React from 'react';

import './main-content.css';

interface MainContentProps {
  children: React.ReactNode;
}

class MainContent extends React.Component<MainContentProps> {
  render() {
    return (
      <main className='main-content'>
        {this.props.children}
      </main>
    );
  }
}

export default MainContent;
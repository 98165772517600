import React from 'react';

import './page-overview-section.css';

interface PageOverviewSectionProps {
  header: string;
  children: React.ReactNode;
}

class PageOverviewSection extends React.Component<PageOverviewSectionProps> {
  render() {
    return (
      <section className='page-overview-section'>
        <h1>{this.props.header}</h1>
        {this.props.children}
      </section>
    );
  }
}

export default PageOverviewSection;
import React from "react";

import "./card-button.css";

interface CardButtonProps {
  text: string;
  onClick?: () => void;
}

class CardButton extends React.Component<CardButtonProps> {
  render() {
    return (
      <button className="card-button" onClick={this.props.onClick}>
        {this.props.text}
      </button>
    );
  }
}

export default CardButton;
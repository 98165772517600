import React from 'react';

import NavBar from '@components/nav-bar';
import MainContent from '@components/main-content';
import FooterBar from '@components/footer-bar';
import PageOverviewSection from '@components/page-overview-section';
import BackgroundContentCard from '@components/background-content-card';

import EastHighImg from '@images/east-high-img.png';
import WaltersStateImg from '@images/walters-state-img.png';
import ETSUimg from '@images/etsu-img.png';
import USAFimg from '@images/usaf-img.png';
import NorthropImg from '@images/ng-img.png';
import SAICimg from '@images/saic-img.png';
import TrueAnomalyImg from '@images/ta-img.png';
import RogueImg from '@images/rogue-img.png';

class AboutPage extends React.Component{
  render() {
    return (
      <body>
        <NavBar />
        <MainContent>
          <PageOverviewSection header="Brandon's Background">
            There's no concise way to describe my background.  I'm less interested in a single field and more interested
            in <b>solving hard problems</b>.  If you haven't died of boredom yet, scroll down to see a more detailed
            background.
          </PageOverviewSection>
          <BackgroundContentCard header="Morristown Hamblen High School East" image={EastHighImg}>
            I went to high school in Morristown, Tennessee.  I graduated in 2008 as the <b>salutatorian</b>. While 
            attending East High, I was president of the <b>Beta Club</b> and captain of the <b>golf team</b>.  Outside 
            of school, I spent time with the Tennessee Valley Authority (TVA) as a <b>CAD Technician</b> and worked a 
            variety of customer service jobs.
          </BackgroundContentCard>
          <BackgroundContentCard header="Walters State Community College" image={WaltersStateImg}>
            I attended Walters State Community College in Morristown, Tennessee for one year while choosing the best 
            four-year college for my goals.  I spent one semester playing <b>collegiate golf</b> before transferring.  
            Professionally, I spent time working after-school programs for at-risk youth at places 
            like <b>Douglas Cherokee Economic Authority</b> and <b>Boys and Girls Club of America</b>.  I also continued 
            to work various customer service jobs where I began to fill <b>leadership</b> roles.
          </BackgroundContentCard>
          <BackgroundContentCard header="East Tennessee State University" image={ETSUimg}>
            I transferred to East Tennessee State University in Johnson City, Tennessee in 2010. I worked at the college
            and independently as a <b>STEM tutor</b> for peers and high school students. I also continued to work
            retail and customer service jobs where I began to gain <b>budgetary responsibilities</b>. I 
            graduated <b>cum laude</b> in 2013 with a <b>Bachelor of Science in Physics</b> and 
            a <b>minor in Mathematics</b>.
          </BackgroundContentCard>
          <BackgroundContentCard header="Tennessee Education System" image={EastHighImg}>
            I returned to East High School for one year as an <b>educator</b> in the <b>Mathematics Department</b>.  
            Before the school year, I received commendations for scoring in the <b>top 15%</b> on the Praxis II 
            Mathematics Content Knowledge exam. I finished the year by receiving the state's <b>highest evaluation</b>.
          </BackgroundContentCard>
          <BackgroundContentCard header="United States Air Force" image={USAFimg}>
            I was in the United States Air Force for three years as a <b>Mission Planner</b> and <b>Orbital Analyst</b> for 
            the <b>Geosynchronous Space Situational Awareness Program</b>.  I presented my work 
            on <b>Maneuver Forensics for Rendezvous and Proximity Operations</b> at <b>MIT</b>.  Soon after, I was 
            awarded the inaugural <b>Innovator of the Year</b> award and given <b>national recognition</b> from 
            the Chief of Staff of the Air Force for the efforts.
          </BackgroundContentCard>
          <BackgroundContentCard header="Northrop Grumman" image={NorthropImg}>
            I worked at Northrop Grumman for four and a half years as a <b>Lead Orbital Analyst</b> for 
            multiple <b>rendezvous-capable space vehicles</b>.  I was responsible for leading the planning and analysis 
            team during real-world operations.
          </BackgroundContentCard>
          <BackgroundContentCard header="Science Applications International Corporation" image={SAICimg}>
            I worked at SAIC for one year as a <b>modeling and simulation engineer</b> and <b>integration lead</b> for
            on-orbit constellation command and control systems.
          </BackgroundContentCard>
          <BackgroundContentCard header="Rogue Solutions" image={RogueImg}>
            I have been an independent consultant since Dec 2022.  I have supported <b>modeling and simulation</b> as 
            well as <b>instruction</b> for future space operators.  I specialize in <b>software development</b> of
            tools for <b>space domain awareness</b>.
          </BackgroundContentCard>
          <BackgroundContentCard header="True Anomaly" image={TrueAnomalyImg}>
            I have been the <b>Director of Space Operations</b> for True Anomaly since Feb 2023.  I lead readiness of 
            sustainment engineers and guide product development for <b>space domain awareness</b> applications.
          </BackgroundContentCard>
        </MainContent>
        <FooterBar />
      </body>
    );
  }
}

export default AboutPage;

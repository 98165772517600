import React from "react";

import './background-content-card.css';

interface BackgroundImageCardProps {
  children: React.ReactNode;
  image: string;
  header: string;
}

class BackgroundImageCard extends React.Component<BackgroundImageCardProps> {
  render() {
    return (
      <section className="background-content-card">
        <img src={this.props.image} alt="background" />
        <h2>{this.props.header}</h2>
        <p>
          {this.props.children}
        </p>
      </section>
    );
  }
}

export default BackgroundImageCard;
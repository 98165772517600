import React from "react";
import { Link } from "react-router-dom";

import "./content-preview-card.css";

import CardButton from "@components/card-button";

interface ContentPreviewCardProps {
  children: React.ReactNode;
  header: string;
  buttonText: string;
  mainLink: string;
}

class ContentPreviewCard extends React.Component<ContentPreviewCardProps> {

  render() {
    return (
      <section className="content-preview-card">
        <h2>{this.props.header}</h2>
        <p>
          {this.props.children}
        </p>
        <Link to={this.props.mainLink}>
          <CardButton text={this.props.buttonText}/>
        </Link>
      </section>
    );
  }
}

export default ContentPreviewCard;
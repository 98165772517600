import React from 'react';

import './nav-bar-link.css';

interface NavBarLinkProps {
  icon: string;
  text: string;
  link: string;
}

class NavBarLink extends React.Component<NavBarLinkProps> {
  render() {
    return  (
      <a href={this.props.link} className="nav-bar-link">
        <img src={this.props.icon} alt={this.props.text} className="nav-bar-icon"/>
        {this.props.text}
      </a>
    );
  }
}

export default NavBarLink;
import React from 'react';

import NavBar from '@components/nav-bar';
import MainContent from '@components/main-content';
import FooterBar from '@components/footer-bar';
import PageOverviewSection from '@components/page-overview-section';
import ContentPreviewGroup from '@components/content-preview-group';
import ContentPreviewCard from '@components/content-preview-card';

class HomePage extends React.Component{

  render() {
    return (
      <>
        <NavBar/>
        <MainContent>
          <PageOverviewSection header="Welcome to Brandon's latest side project.">
            Click around to get more details about my background, projects, and hobbies.
          </PageOverviewSection>
          <ContentPreviewGroup>
            <ContentPreviewCard header="Background" buttonText="Die of Boredom" mainLink='/about'>
              As a hobby collector who constantly gets distracted, I created <b>KNOWSPACE</b> to consolidate my projects
              and experiences in hopes that my work can shorten the learning curve for the next ambitious nerd.
            </ContentPreviewCard>
            <ContentPreviewCard header="Projects" buttonText="Learn Something" mainLink='/projects'>
              The majority of my projects are related to <b>space mission engineering and analysis</b>. The projects span
              everything from backend algorithms to visualization dashboards. Most source code is available on my GitHub
              page.
            </ContentPreviewCard>
            <ContentPreviewCard header="Hobbies" buttonText="Get Distracted" mainLink='/hobbies'>
              Sometimes I do things not related to work. Like any good Tennessee-Colorado hybrid, I enjoy basically 
              all <b>outdoor activities</b>. I use <b>photography</b> as the vessel for capturing those moments, and I 
              welcome you to judge my work.
            </ContentPreviewCard>
          </ContentPreviewGroup>
        </MainContent>
        <FooterBar />
      </>
    )
  }
}

export default HomePage;

import React from "react";

import NavBar from "@components/nav-bar";
import MainContent from "@components/main-content";
import FooterBar from "@components/footer-bar";

import PageOverviewSection from "@components/page-overview-section";

class ContactPage extends React.Component {
  render() {
    return (
      <>
        <NavBar />
        <MainContent>
          <PageOverviewSection header="Contact Me">
            This page is still under construction.  Please use the mail icon at the bottom of the page to contact
            me in the meantime.
          </PageOverviewSection>
        </MainContent>
        <FooterBar />
      </>
    );
  }
}

export default ContactPage;
import React from 'react';

import './nav-bar.css';

import ClickableIcon from '@components/clickable-icon';
import NavBarLinkGroup from '@components/nav-bar-link-group';
import MenuIcon from '@icons/menu-icon.png';
import NavBarLink from "@components/nav-bar-link";
import HomeIcon from "@icons/home-icon.png";
import AboutIcon from "@icons/about-icon.png";
import ContactIcon from "@icons/contact-icon.png";

class NavBar extends React.Component {
  render() {
    return (
      <nav className='nav-bar'>
        <ClickableIcon src={MenuIcon} alt="menu"/>
        <NavBarLinkGroup>
          <NavBarLink icon={HomeIcon} text="Home" link="."/>
          <NavBarLink icon={AboutIcon} text="About" link="./about"/>
          <NavBarLink icon={ContactIcon} text="Contact" link="./contact"/>
        </NavBarLinkGroup>
      </nav>
    );
  }
}

export default NavBar;

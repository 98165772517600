import React from 'react';

import './content-preview-group.css';

interface ContentPreviewGroupProps {
  children: React.ReactNode;
}

class ContentPreviewGroup extends React.Component<ContentPreviewGroupProps> {
  render() {
    return (
      <div className='content-preview-group'>
        {this.props.children}
      </div>
    );
  }
}

export default ContentPreviewGroup;
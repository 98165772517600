import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import HomePage from '@pages/home-page';
import AboutPage from '@pages/about-page';
import ContactPage from '@pages/contact-page';
import HobbiesPage from '@pages/hobbies-page';
import ProjectsPage from '@pages/projects-page';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Router>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/hobbies" element={<HobbiesPage />} />
      <Route path="/projects" element={<ProjectsPage />} />
    </Routes>
  </Router>
);

import React from 'react';

import './clickable-icon.css';

interface ClickableIconProps {
  src: string;
  alt: string;
}

class ClickableIcon extends React.Component<ClickableIconProps> {
  render() {
    return (
      <img src={this.props.src} alt={this.props.alt} className="clickable-icon"/>
    );
  }
}

export default ClickableIcon;
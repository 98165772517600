import React from "react";

import "./nav-bar-link-group.css";

interface NavBarLinkGroupProps {
  children: React.ReactNode;
}

class NavBarLinkGroup extends React.Component<NavBarLinkGroupProps> {
  render() {
    return (
      <div className="nav-bar-link-group">
        {this.props.children}
      </div>
    );
  }
}

export default NavBarLinkGroup;
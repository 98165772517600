import React from 'react';
import './footer-bar.css';
import LinkedInIcon from '@icons/linkedin-icon.png';
import GitHubIcon from '@icons/github-icon.png';
import InstagramIcon from '@icons/instagram-icon.png';
import EmailIcon from '@icons/email-icon.png';

class FooterBar extends React.Component {
  render() {
    return (
        <footer>
            <div className="footer-column">
                All content has been independently produced.
            </div>
            <div className="footer-column">
                <a href="https://linkedin.com/in/brandon-sexton" align-items="center">
                    <img src={LinkedInIcon} alt="linkedin" className="clickable-icon"/>
                </a>
                <a href="https://github.com/brandon-sexton">
                    <img src={GitHubIcon} alt="github" className="clickable-icon"/>
                </a>
                <a href="https://instagram.com/voluntography">
                    <img src={InstagramIcon} alt="instagram" className="clickable-icon"/>
                </a>
                <a href="mailto:brandon.sexton.1@outlook.com">
                    <img src={EmailIcon} alt="email" className="clickable-icon"/>
                </a>
            </div>
            <div className="footer-column">
            &copy; 2024 Brandon Sexton
            </div>
        </footer>
    );
  }
}

export default FooterBar;